import React from 'react';

const GateModal = ({ children }) => {
  return (
    <div className={`gate-modal`}>
      <div className={`gate-modal__window`}>{children}</div>
    </div>
  );
};

export default GateModal;
