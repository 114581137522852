import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import RichText from '../RichText';
import BackgroundImage from 'gatsby-background-image';
import { inViewTriggerThresholds } from '../../animationGlobals';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const LandingPageHero = ({ title, subtitle, variant, cardImage }) => {
  
  return (
    <div className={`lp-hero lp-hero__header lp-hero--${variant}`}>
      <BackgroundImage
        tag={'div'}
        fluid={cardImage?.fluid}
        className="lp-hero__header-inner"
      />
      <div className={'lp-hero__inner'}>
        <div className="lp-hero__content">
          <h1 className="lp-hero__title">{title}</h1>
          {subtitle &&
            (subtitle?.json ? (
              <RichText
                className="lp-hero__subtitle"
                source={subtitle.json}
              />
            ) : (
              <p className="lp-hero__subtitle">{subtitle}</p>
            ))}
        </div>{' '}
      </div>
    </div>
  );
};

LandingPageHero.propTypes = {
  variant: PropTypes.oneOf(['left-aligned', 'centered', 'centered-left-aligned']),
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cardImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

LandingPageHero.defaultProps = {
  variant: 'centered-left-aligned',
  title: 'Hero title',
  subtitle: null,
  cardImage: null,
};

export default LandingPageHero;
