import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../Button';
import { Form } from '../Form';

const EmailBanner = ({ title, form, theme }) => {
  const [formStatus, setFormStatus] = useState({
    submitted: false,
    success: false,
  });

  const successMessage = form.successTitle;
  const errorMessage = form.errorTitle;

  return (
    <div className={`email-banner email-banner--${theme}`}>
      <div className="email-banner__wrapper">
        <div className="email-banner__inner">
          <div className="email-banner__content">
            <h2 className="email-banner__title">{title}</h2>
          </div>
          <div className="email-banner__form">
            {formStatus.submitted ? (
              <div className="email-banner__submit-message">
                <p>{formStatus.success ? successMessage : errorMessage}</p>
              </div>
            ) : (
              <Form
                {...form}
                onSubmitSuccess={() =>
                  setFormStatus({ submitted: true, success: true })
                }
                onSubmitFail={() =>
                  setFormStatus({ submitted: true, success: false })
                }
                onSubmitError={() =>
                  setFormStatus({ submitted: true, success: false })
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

EmailBanner.propTypes = {
  theme: PropTypes.oneOf(['dark', 'light', 'yellow']),
};

EmailBanner.defaultProps = {
  theme: 'light',
};

export default EmailBanner;
