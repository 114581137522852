import React from 'react';
import PropTypes from 'prop-types';
import { PillItem, PillsList } from '../PillsList';
import BackgroundImage from 'gatsby-background-image';
import { navigate } from 'gatsby';
import qs from 'qs';
import Link from '../Link';

const BlogCardSide = (props) => {
  const {
    contentType,
    roles,
    categories,
    featuredContent,
    showIsFeatured,
    shortTitle,
    slug,
    externalResource,
    externalResourceUrl,
    cardImage,
    variant,
  } = props;

  const cardUrl = externalResource ? externalResourceUrl : `/resources/${slug}`;

  const handlePillClick = () => {
    const filterState = { contentType: [contentType.slug] };
    const encoded = qs.stringify(filterState, {
      indices: false,
      encode: false,
      arrayFormat: 'comma',
    });
    const url = `/resources/?${encoded}`;
    navigate(url);
  };

  return (
    <div className={`blog-card blog-card--${variant}`}>
      <div className="blog-card__inner">
        <div className="blog-card__header">
          <Link href={cardUrl}>
            <BackgroundImage
              tag={'div'}
              fluid={cardImage?.fluid}
              className="blog-card__header-inner"
              style={{
                position: 'absolute',
              }}
            />
          </Link>
        </div>

        <div className="blog-card__body">
          <div className="blog-card__pills">
            <PillsList>
              {/* This can be omitted if we don't want to show featured */}
              {featuredContent && showIsFeatured ? (
                <PillItem isClickable={false}>Featured</PillItem>
              ) : null}
              {contentType ? (
                <PillItem onClick={handlePillClick}>
                  {contentType.title}
                </PillItem>
              ) : null}
              {/* {[...roles, ...categories].map(({ title, slug }) => (
                <PillItem>{title}</PillItem>
              ))} */}
            </PillsList>
          </div>
          <h5 className="blog-card__title">
            <Link href={cardUrl}>{shortTitle}</Link>
          </h5>
          <div className="blog-card__cta">
            <Link href={cardUrl}>Learn more</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

BlogCardSide.propTypes = {
  variant: PropTypes.oneOf(['horizontal', 'vertical']),
  showIsFeatured: PropTypes.bool,
};

BlogCardSide.defaultProps = {
  variant: 'vertical',
  showIsFeatured: true,
};

export default BlogCardSide;
