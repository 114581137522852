import React, { useState, useEffect } from 'react';
import BlogCardSide from '../BlogCardSide';
import SocialIcons from '../SocialIcons';
import RichText from '../RichText';
import Link from '../Link';
import GateModal from './GateModal';
import { Button } from '../Button';
import { useCookie } from 'react-use';
import { SocialItemsData } from '../SocialIcons';
import { Form } from '../Form';

const DownloadBox = ({ url, title }) => {
  return (
    <div className="lp-detail__download">
      <div className="lp-detail__download-inner">
        <div className="lp-detail__download-title">{title}</div>
        <div className="lp-detail__download-button">
          <Button href={url} target={'__blank'} download={true}>
            Download
          </Button>
        </div>
      </div>
    </div>
  );
};

const BlogDetailSidebar = ({ resource }) => {
  const shareWhiteList = ['twitter', 'linkedin', 'facebook'];

  const shareUrl = typeof window !== 'undefined' ? window.location : '';
  const shareText = '';

  return (
    <div className="lp-detail__sidebar">
      {resource && (
        <BlogCardSide
          {...resource}
          showIsFeatured={false}
          variant={'horizontal'}
        />
      )}
      <div className="lp-detail__share">
        <h5>Share</h5>

        <div className="lp-detail__share-icons">
          <SocialIcons
            isShareLink={true}
            shareUrl={shareUrl}
            shareText={shareText}
            theme="light"
            items={SocialItemsData.filter((item) =>
              shareWhiteList.includes(item.slug),
            )}
          />
        </div>

        <p>Have questions or want to get in touch?</p>
        <p>
          <Link href="https://proedge.pwc.com/contact">Contact ProEdge</Link>
        </p>
      </div>
    </div>
  );
};

const FormErrorMessage = () => {
  return (
    <div className="gate-form__message">
      <h3 className="gate-form__submit-title">We're sorry</h3>
      <p className="gate-form__submit-subtitle">
        Something went wrong. Refresh the page and try again or come back later.
      </p>
      <p className="gate-form__submit-footer">
        Need help? Have a question? <Link href="https://proedge.pwc.com/contact">Contact us</Link>.
      </p>
    </div>
  );
};

const LandingPageDetailBody = (props) => {
  const {
    slug,
    gatedContent,
    gatedDownloadIncluded,
    downloadFile,
    downloadTitle,
    gatedContentPreviewText,
    cardImage,
    bodyText,
    form,
  } = props;

  const COOKIE_NAME = 'PWC_PROEDGE_USER_GATE';
  // const COOKIE_VALUE_GATED = 0;
  const COOKIE_VALUE_UNGATED = '1';

  const [value, updateCookie, deleteCookie] = useCookie(COOKIE_NAME);
  const [showGate, setShowGate] = useState(gatedContent);

  useEffect(() => {
    let params = new URLSearchParams(document.location.search.substring(1));
    let utm_cookie_status = params.get("utm_vip");
    if(utm_cookie_status) {
      setShowGate(false);
      updateCookie(COOKIE_VALUE_UNGATED);
      localStorage.setItem('utm_cookie_status', utm_cookie_status);
      localStorage.setItem('utm_timestamp_cookie_status', new Date());
    }
  }, []);

 
  useEffect(() => {
    if (gatedContent && value === COOKIE_VALUE_UNGATED) {
      // if they've got the cookie, hide the gate.
      setShowGate(false);
    }
  }, []);

  useEffect(() => {
    if (gatedContent && !showGate) {
      // if they've passed the gate and the cookie isn't there or 0, update it
      updateCookie(COOKIE_VALUE_UNGATED);
    }
  }, [showGate]);

  return (
    <div className="lp-detail__body">
      {gatedContentPreviewText && (
        <div
          className={`lp-detail__content lp-detail__content--preview`}
        >
          <div className="lp-detail__content-inner">
            <RichText source={gatedContentPreviewText?.json} />
          </div>
        </div>
      )}

      <div
        className={`lp-detail__content lp-detail__content--restricted lp-detail__content--${
          showGate ? 'not-visible' : 'visible'
        }`}
      >
        {showGate && (
          <GateModal>
            <Form {...form} onSubmitSuccess={() => setShowGate(false)} />
          </GateModal>
        )}

        <div className="lp-detail__content-inner">
          <RichText source={bodyText?.json} />
          {gatedDownloadIncluded && downloadFile?.file && (
            <DownloadBox
              title={downloadTitle?.downloadTitle}
              url={downloadFile.file.url}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const LandingPageDetail = (props) => {
  const { sidebarResource, form } = props;
  return (
    <div className="lp-detail">
      <div className="lp-detail__inner">
        <LandingPageDetailBody form={form} {...props} />
        {/* <BlogDetailSidebar resource={sidebarResource} /> */}
      </div>
    </div>
  );
};

export default LandingPageDetail;
