import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonContainer } from '../Button';
import BackgroundImage from 'gatsby-background-image';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { inViewTriggerThresholds } from '../../animationGlobals';

const DemoBanner = ({ title, productSlug, ctaText, ctaLink, theme, heroBg }) => {

  return (
    <section className={`demo-banner--${theme}`}>
      <BackgroundImage
        tag={'div'}
        fluid={heroBg?.fluid}
        style={{
          backgroundSize: 'contain',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
        }}
        className={`demo-banner`}>
        <div className="demo-banner__inner">
          <h2 className="demo-banner__title">
          Take the first step towards workforce transformation
          </h2>
          <ButtonContainer align="center">
            <Button theme={'dark'} href={ctaLink}>
              Get started
            </Button>
          </ButtonContainer>
        </div>
        </BackgroundImage>
      </section>
  );
};

DemoBanner.propTypes = {
  theme: PropTypes.oneOf(['dark', 'light', 'yellow']),
};

DemoBanner.defaultProps = {
  theme: 'light',
  productSlug: 'default',
  ctaLink: '/demo',
  heroBg: {
    fluid: {
      url: '',
    },
    fixed: {
      url: '',
    },
  },
};

export default DemoBanner;
